.wmde-markdown-color .token.tag .attr-value {
  color: #032f62;
}
.wmde-markdown-color .token.property,
.wmde-markdown-color .token.tag,
.wmde-markdown-color .token.boolean,
.wmde-markdown-color .token.number,
.wmde-markdown-color .token.function-name,
.wmde-markdown-color .token.constant,
.wmde-markdown-color .token.symbol,
.wmde-markdown-color .token.deleted {
  color: #0060c9;
}
.wmde-markdown-color .token.punctuation {
  color: #a0a0a0;
}
.wmde-markdown-color code[class*="language-"] {
  color: black;
}
.wmde-markdown-color code[class*="language-"] .token.selector,
.wmde-markdown-color code[class*="language-"] .token.attr-name,
.wmde-markdown-color code[class*="language-"] .token.string,
.wmde-markdown-color code[class*="language-"] .token.char,
.wmde-markdown-color code[class*="language-"] .token.function,
.wmde-markdown-color code[class*="language-"] .token.builtin {
  color: #6f42c1;
}
.wmde-markdown-color code[class*="language-"] .token.inserted {
  color: #22863a;
  background-color: #f0fff4;
}
.wmde-markdown-color code[class*="language-"] .token.deleted {
  color: #b31d28;
  background-color: #ffeef0;
}
.wmde-markdown-color code[class*="language-"] .token.class-name {
  color: #6f42c1;
}
.wmde-markdown-color code[class*="language-"] .code-block {
  color: #032f62;
}
.wmde-markdown-color code[class*="language-"] .token.comment,
.wmde-markdown-color code[class*="language-"] .token.block-comment,
.wmde-markdown-color code[class*="language-"] .token.prolog,
.wmde-markdown-color code[class*="language-"] .token.doctype,
.wmde-markdown-color code[class*="language-"] .token.cdata {
  color: #7D8B99;
}
.wmde-markdown-color code[class*="language-"] .token.punctuation {
  color: #a0a0a0;
}
.wmde-markdown-color code[class*="language-"] .token.operator,
.wmde-markdown-color code[class*="language-"] .token.entity,
.wmde-markdown-color code[class*="language-"] .token.url,
.wmde-markdown-color code[class*="language-"] .token.variable {
  color: #d73a49;
  background: rgba(255, 255, 255, 0.5);
}
.wmde-markdown-color code[class*="language-"] .token.atrule,
.wmde-markdown-color code[class*="language-"] .token.attr-value {
  color: #004698;
}
.wmde-markdown-color code[class*="language-"] .token.keyword {
  color: #d63200;
}
.wmde-markdown-color code[class*="language-"] .token.regex,
.wmde-markdown-color code[class*="language-"] .token.important {
  color: #e90;
}
.wmde-markdown-color code[class*="language-"] .token.string {
  color: #0a53c1;
}
